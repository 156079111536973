
import { state } from "@/store/app-store";

export default {
  props: {
    profile: {},
    socialMediaAccounts: {},
    menuItems: {},
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  }
};
