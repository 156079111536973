import api from "./api-v1-config";
import { ExperienceModelType } from "@/models/experience";

// TODO: get token param
export async function addExperience(e: ExperienceModelType) {
    return await (await api.post("Experiences", e, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJJZCI6ImM0MDc0NjU2LWM3ZDUtNGMxNC1iYzNlLWI4OTI1ZjFjZTk3NSIsInN1YiI6ImZhdGloIiwiZW1haWwiOiJmYXRpaCIsImp0aSI6Ijc5NjBiNjVlLTdhYTgtNDViOS1iYWQ2LTAyN2U2Y2IwNmE5YSIsIm5iZiI6MTcwOTQ3Mzg4MywiZXhwIjoxNzA5NDc0MTgzLCJpYXQiOjE3MDk0NzM4ODMsImlzcyI6Imh0dHBzOi8vZmF0aWhkdW1hbmxpLmNvbSIsImF1ZCI6Imh0dHBzOi8vZmF0aWhkdW1hbmxpLmNvbSJ9.pDmd6-00Ug2vtcoeZZKZs5ggUA0CzFN6gMrbvOVZHlWS_BN6kbJ4LSlW3KzAsKSgLHessBB5ODnu9dyeYkQ_Ag' }})).data;
}

export async function getExperiences(): Promise<ExperienceModelType[]> {
    return await (await api.get("Experiences")).data;
}

export async function getExperience(id: number): Promise<ExperienceModelType> {
    return await (await api.get("Experiences/" + id)).data;
}

export async function updateExperience(id: number, e: ExperienceModelType) {
    return await (await api.put("Experiences/" + id, e, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJJZCI6ImM0MDc0NjU2LWM3ZDUtNGMxNC1iYzNlLWI4OTI1ZjFjZTk3NSIsInN1YiI6ImZhdGloIiwiZW1haWwiOiJmYXRpaCIsImp0aSI6Ijc5NjBiNjVlLTdhYTgtNDViOS1iYWQ2LTAyN2U2Y2IwNmE5YSIsIm5iZiI6MTcwOTQ3Mzg4MywiZXhwIjoxNzA5NDc0MTgzLCJpYXQiOjE3MDk0NzM4ODMsImlzcyI6Imh0dHBzOi8vZmF0aWhkdW1hbmxpLmNvbSIsImF1ZCI6Imh0dHBzOi8vZmF0aWhkdW1hbmxpLmNvbSJ9.pDmd6-00Ug2vtcoeZZKZs5ggUA0CzFN6gMrbvOVZHlWS_BN6kbJ4LSlW3KzAsKSgLHessBB5ODnu9dyeYkQ_Ag'}}));
}

export async function removeExperience(id: number) {
    return await (await api.delete("Experiences/" + id));
}
