import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import Vue3Storage from "vue3-storage";
import { StorageType } from "vue3-storage";

const app = createApp(App);

app.use(router);
app.use(Vue3Storage, { namespace: "pro_", storage: StorageType.Local });
app.use(BootstrapIconsPlugin);
app.mount("#app");
