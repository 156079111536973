import api from "./api-v1-config";
import { ProfileModelType } from "@/models/profile";
import { SocialMediaAccountModelType } from "@/models/socialmediaccount";

import { useStorage } from "vue3-storage";

const useToken = useStorage();
const token = useToken.getStorageSync('appToken');

export async function getProfile(): Promise<ProfileModelType> {
    return await (await api.get("Profile")).data;
}

export async function updateProfile(profile: ProfileModelType) {
    return await api.put("Profile", profile);
}

export async function updateSummary(summary: string) {
    return await api.put("Profile/Summary", { summary: summary });
}

export async function getSocialMediaAccounts(): Promise<SocialMediaAccountModelType[]> {
    return await (await api.get("Profile/SocialMediaAccounts")).data;
}
