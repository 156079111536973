<template>
    <div>
  <main class="">
    <div class="container">
      <div class="row">
        <div class="col-xl-4">
          <Sidebar v-if="!isAdmin" 
                         :profile="profile"
                         :socialMediaAccounts="socialMediaAccounts"
                         :menuItems="menuItems" />
          <AdminSidebar v-if="isAdmin" />
        </div>

        <div class="col-xl-8">
          <router-view :key="$route.path" 
                             :profile="profile"
                             :experiences="experiences" />
        </div>
      </div>
    </div>
  </main>

  <footer class="footer mt-auto py-3 bg-light">
    <div class="container centered">
      <span class="text-muted"
        >©️ Fatih Dumanli - 2024 - Built with
        <a href="https://go.dev/" target="_blank">Golang</a> &
        <a href="https://vuejs.org/" target="_blank">Vuejs</a></span
      >
    </div>
  </footer>

  <div class="container">
    <LoadingScreen v-if="isLoading" />
  </div>
    </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import AdminSidebar from "./components/AdminSidebar.vue";
import { getAppInfo } from "@/api/app-service";
import { getProfile, getSocialMediaAccounts } from "@/api/profile-service";
import { state, appToken } from "@/store/app-store.ts";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { useStorage } from "vue3-storage";
import { getExperiences } from "@/api/experience-service";

export default {
  name: "App",
  data() {
      return {
          profile: {},
          socialMediaAccounts: {}
      }
  },
  computed: {
    isAdmin() {
      const useToken = useStorage();
      const token = useToken.getStorageSync("appToken");
      return (
        this.$route.name?.toString().includes("admin") && token !== undefined
      );
    },
    isLoading() {
      return state.isLoading;
    },
  },
  watch: {
    $route(to, from) {
      document.title = "Fatih Dumanli";
    },
  },
  components: {
    Sidebar,
    AdminSidebar,
    LoadingScreen,
  },
  async mounted() {
    state.isLoading = true;
    if (state.pages.length === 0 || state.summary === "") {
      state.isLoading = true;
      try {
        const data = await getAppInfo();
        const profile = await getProfile();
        const socialMediaAccounts = await getSocialMediaAccounts();
        this.profile = profile;
        this.profile.socialMediaAccounts = socialMediaAccounts;
        this.socialMediaAccounts = socialMediaAccounts;
        const menuItems = [{ title: "Home", path: "/", order: 1 }, { title:
            "Blog", path: "/blog", order: 2 }, { title: "Portfolio", path:
                "/pages/portfolio", order: 3 }];
        this.menuItems = menuItems;
        this.experiences = await getExperiences();

        //state.pages = data.data.pages;
        //state.briefTitle = data.data.briefTitle;
      } catch (e) {
        console.log("err: " + e);
      } finally {
        state.isLoading = false;
      }
    }
  },
};
</script>

<style>
h3 {
  font-weight: 900;
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/opensans/OpenSans-Regular.ttf");
}

body {
  font-family: Open Sans !important;
  color: #605e5e !important;
}

main > .container {
  padding: 20px 15px 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}

.centered {
  text-align: center;
}

.box {
  background: white;
}

.box-header {
  padding: 10px;
  margin-top: 10px;
  text-decoration: underline;
}

.box-body {
  padding: 10px;
}

.horizontal {
  margin: 0;
  padding: 0;
}

.horizontal li {
  display: inline-block;
  padding: 10px;
}

.btn-default {
  border: 1px solid var(--bs-gray) !important;
}

.bg-primary {
  background-color: #3CB371 !important;
}

h4 {
  color: #525252 !important;
  font-weight: bold;
}
</style>
