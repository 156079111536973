<template>
  <ul class="list-group">
    <li class="list-group-item">
      <router-link to="/admin" class="nav-link">Home</router-link>
    </li>

    <li class="list-group-item">
      <router-link to="/admin/personal" class="nav-link">Personal</router-link>
    </li>

    <li class="list-group-item">
      <router-link to="/admin/experiences" class="nav-link">Experiences</router-link>
    </li>

    <li class="list-group-item">
      <router-link to="/admin/articles" class="nav-link">Articles</router-link>
    </li>

    <li class="list-group-item">
      <router-link to="/admin/pages" class="nav-link">Pages</router-link>
    </li>
  </ul>
</template>
