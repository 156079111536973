
import { defineComponent, ref } from "vue";
import {
  getArticlesWithDrafts,
  deleteArticleByIdAxiosv1,
} from "@/api/article-services";

export default defineComponent({
  name: "Articles",
  components: {},
  async mounted() {
    this.getArticles();
  },
  data() {
    return {
      articles: [],
      loading: false,
    };
  },
  computed: {
    strip() {
      return (v: string) => v.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  methods: {
    async getArticles() {
      this.loading = true;
      try {
        const { data } = await getArticlesWithDrafts();

        this.articles = data?.map((w: any) => {
          const formattedData = { ...w };
          return formattedData;
        });

      } catch (e) {
        alert("an error has occured, please try again");
      } finally {
        this.loading = false;
      }
    },
    async deleteArticle(id: string) {
      try {
        await deleteArticleByIdAxiosv1(id);
        await this.getArticles();
      } catch (e) {
        alert("couldn't delete the article");
      }
    },
  },
});
