import { reactive } from "vue";
import { AppViewModelType } from "@/models/appViewModel";

const appModel: AppViewModelType = {
    pages: [],
    isLoading: false,
    profile: { title: "aaaa", fullName: "", profilePhoto: "", summary: "" }
};

export const state = reactive(appModel);
