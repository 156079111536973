<template>
  <div>
    <h2>Experiences</h2>
    <div class="row" style="margin-bottom: 20px">
      <router-link to="/admin/experiences/new">Create new Experience</router-link>
    </div>
    <div class="row">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Company</th>
            <th scope="col">Title</th>
            <th scope="col">FromDate</th>
            <th scope="col">ToDate</th>
            <th scope="col">IsPresent</th>
            <th scope="col" style="width: 20%">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in this.experiences" :key="e.id">
            <td>
              <router-link :to="'/admin/experiences/' + e.id">{{
                  e.companyName }}</router-link>
            </td>
            <td> {{ e.title }}</td>
            <td> {{ e.fromDate }}</td>
            <td> {{ e.toDate }}</td>
            <td> {{ e.isPresent }}</td>
            <td>
              <router-link
                :to="'/admin/experiences/' + e.id"
                class="btn btn-warning"
              >
                <b-icon-pencil />
              </router-link>

              <button
                type="button"
                class="btn btn-danger"
                @click="deleteExperience(e.id)"
              >
                <b-icon-trash />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { getExperiences, removeExperience } from "@/api/experience-service";

export default {
  components: {},
  data() {
    return {
      experiences: [],
    };
  },
  computed: {
    strip: function (v) {
      return v.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  async mounted() {
    this.experiences = await getExperiences();
  },
  methods: {
    async deleteExperience(id) {
      await removeExperience(id);
      this.experiences = await getExperiences();
    },
  },
};
</script>

<style scoped>
a {
  margin-right: 5px;
}
.container {
  padding: 2rem 0rem;
}

h4 {
  margin: 2rem 0rem 1rem;
}

td,
th {
  vertical-align: middle;
}

button[type="button"] {
  margin-left: 5px;
}
</style>
