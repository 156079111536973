<template>
    <div>
          <h3>Company Name</h3>
          <div class="row">
            <div class="col-md-12">
              <input class="form-control" v-model="e.companyName" />
            </div>
          </div>

          <h3>Title</h3>
          <div class="row">
            <div class="col-md-12">
              <input class="form-control" v-model="e.title" />
            </div>
          </div>

          <h3>From</h3>
          <div class="row">
            <div class="col-md-12">
              <input class="form-control" v-model="e.fromDate" />
            </div>
          </div>

          <h3>To</h3>
          <div class="row">
            <div class="col-md-12">
              <input class="form-control" v-model="e.toDate" />
            </div>
          </div>

          <div class="row">
            <div id="editor">
              <div v-if="editor">
                <button
                  @click="editor.chain().focus().toggleBold().run()"
                  :class="{ 'is-active': editor.isActive('bold') }"
                >
                  bold
                </button>
                <button
                  @click="editor.chain().focus().toggleItalic().run()"
                  :class="{ 'is-active': editor.isActive('italic') }"
                >
                  italic
                </button>
                <button
                  @click="editor.chain().focus().toggleStrike().run()"
                  :class="{ 'is-active': editor.isActive('strike') }"
                >
                  strike
                </button>
                <button
                  @click="editor.chain().focus().toggleCode().run()"
                  :class="{ 'is-active': editor.isActive('code') }"
                >
                  code
                </button>

                <button
                  @click="addLink"
                  :class="{ 'is-active': editor.isActive('link') }"
                >
                  Add Link
                </button>

                <button
                  @click="removeLink"
                  :class="{ 'is-active': editor.isActive('link') }"
                >
                  Remove Link
                </button>

                <button @click="editor.chain().focus().unsetAllMarks().run()">
                  clear marks
                </button>
                <button @click="editor.chain().focus().clearNodes().run()">
                  clear nodes
                </button>
                <button
                  @click="editor.chain().focus().setParagraph().run()"
                  :class="{ 'is-active': editor.isActive('paragraph') }"
                >
                  paragraph
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
                >
                  h1
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
                >
                  h2
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
                >
                  h3
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
                >
                  h4
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
                >
                  h5
                </button>
                <button
                  @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
                  :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
                >
                  h6
                </button>
                <button
                  @click="editor.chain().focus().toggleBulletList().run()"
                  :class="{ 'is-active': editor.isActive('bulletList') }"
                >
                  bullet list
                </button>
                <button
                  @click="editor.chain().focus().toggleOrderedList().run()"
                  :class="{ 'is-active': editor.isActive('orderedList') }"
                >
                  ordered list
                </button>
                <button
                  @click="editor.chain().focus().toggleCodeBlock().run()"
                  :class="{ 'is-active': editor.isActive('codeBlock') }"
                >
                  code block
                </button>
                <button
                  @click="editor.chain().focus().toggleBlockquote().run()"
                  :class="{ 'is-active': editor.isActive('blockquote') }"
                >
                  blockquote
                </button>
                <button @click="editor.chain().focus().setHorizontalRule().run()">
                  horizontal rule
                </button>
                <button @click="editor.chain().focus().setHardBreak().run()">
                  hard break
                </button>
                <button @click="editor.chain().focus().undo().run()">undo</button>
                <button @click="editor.chain().focus().redo().run()">redo</button>
              </div>
              <editor-content :editor="editor" />
            </div>
          </div>
          <div class="row">
            <button class="btn btn-success" @click="save">Create Experience</button>
          </div>
    </div>
</template>

<script>
import { addExperience } from "@/api/experience-service";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";

Link.configure({
  autolink: false,
});

export default {
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      e: {},
    };
  },
  async mounted() {
    this.e = {};
    this.editor = new Editor({
      extensions: [StarterKit, Link],
      content: this.e.description,
    });
  },
  methods: {
    async save() {
      this.e.description = this.editor.getHTML();

      await addExperience(this.e);

      window.location.href = "/admin/experiences";
    },
    addLink() {
      const link = prompt();

      if (link === "") return;

      this.editor.commands.setLink({ href: link });
    },
    removeLink() {
      this.editor.commands.unsetLink();
    },
  },
};
</script>
