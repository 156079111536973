import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53a02013"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card mainCard" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "card-body centered" }
const _hoisted_4 = { class: "centered h1Class" }
const _hoisted_5 = { class: "card-header centered icons" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { id: "mainMenu" }
const _hoisted_10 = { class: "list-group" }
const _hoisted_11 = { class: "card compactCard" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-sm-4" }
const _hoisted_14 = { class: "centered h1Class" }
const _hoisted_15 = { class: "col-sm-4" }
const _hoisted_16 = { class: "icons" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["href"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "col-sm-4" }
const _hoisted_21 = { id: "compactMenu" }
const _hoisted_22 = { class: "nav nav-pills" }
const _hoisted_23 = { class: "card mobileCard" }
const _hoisted_24 = { class: "row" }
const _hoisted_25 = { class: "col-sm-4 centered" }
const _hoisted_26 = { class: "centered h1Class" }
const _hoisted_27 = { class: "col-sm-4 centered" }
const _hoisted_28 = { class: "icons" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = ["href"]
const _hoisted_31 = ["src"]
const _hoisted_32 = { class: "col-sm-4 centered" }
const _hoisted_33 = { id: "mobileMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: $props.profile.profilePhoto,
        class: "rounded-image"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.profile.fullName), 1)
            ]),
            _: 1
          })
        ]),
        _createTextVNode(" " + _toDisplayString($props.profile.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        ($props.socialMediaAccounts.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, "No items yet"))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.socialMediaAccounts, (socialLink) => {
          return (_openBlock(), _createElementBlock("a", {
            href: socialLink.profileUrl,
            target: "_blank",
            key: socialLink.id
          }, [
            _createElementVNode("img", {
              src: socialLink.iconUri,
              class: "icon"
            }, null, 8, _hoisted_8)
          ], 8, _hoisted_7))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItems, (menuItem) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: menuItem.path,
            class: "list-group-item",
            key: menuItem.id,
            "active-class": "activeLink"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(menuItem.title), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("h1", _hoisted_14, [
            _createVNode(_component_router_link, {
              to: "/",
              "active-class": "none"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.profile.fullName), 1)
              ]),
              _: 1
            })
          ]),
          _createTextVNode(" " + _toDisplayString($props.profile.title), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            ($props.socialMediaAccounts.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, "No items yet"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.socialMediaAccounts, (socialLink) => {
              return (_openBlock(), _createElementBlock("a", {
                href: socialLink.profileUrl,
                target: "_blank",
                key: socialLink.id
              }, [
                _createElementVNode("img", {
                  src: socialLink.iconUri,
                  class: "icon"
                }, null, 8, _hoisted_19)
              ], 8, _hoisted_18))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("ul", _hoisted_22, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItems, (menuItem) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "nav-item",
                  key: menuItem.id
                }, [
                  _createVNode(_component_router_link, {
                    to: menuItem.path,
                    class: "list-group-item",
                    "active-class": "activeLink"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(menuItem.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("h1", _hoisted_26, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.profile.fullName), 1)
              ]),
              _: 1
            })
          ]),
          _createTextVNode(" " + _toDisplayString($props.profile.title), 1)
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            ($props.socialMediaAccounts.length === 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_29, "No items yet"))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.socialMediaAccounts, (socialLink) => {
              return (_openBlock(), _createElementBlock("a", {
                href: socialLink.profileUrl,
                target: "_blank",
                key: socialLink.id
              }, [
                _createElementVNode("img", {
                  src: socialLink.iconUri,
                  class: "icon"
                }, null, 8, _hoisted_31)
              ], 8, _hoisted_30))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItems, (menuItem) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                to: menuItem.path,
                key: menuItem.id,
                "active-class": "activeLink"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(menuItem.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}