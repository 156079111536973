<template>
    <div>
  <div class="row">
    <h1>{{ page.title }}</h1>
    <hr style="margin-top: 10px;"/>
  </div>
  <div class="row">
    <div class="col-md-12 ProseMirror">
      <div v-html="page.content" style="margin-top:30px; padding:10px;" />
    </div>
  </div>
  </div>
</template>

<script>
import { getPageBySlug } from "@/api/page-service";
import { encode, decode } from 'js-base64';

export default {
  components: {},
  data() {
    return {
      page: {},
    };
  },
  async mounted() {
    const slug = this.$route.fullPath.split('/')[2];

    const page = await getPageBySlug(slug);

    page.content = page.content;
    this.page = page;
  },
};
</script>


<style scoped>

.article {
  padding: 25px;
  background: white;
}
.article-meta {
  font-size: 0.8rem;
  font-weight: bold;
  color: #5e5e5ef1;
}

.article-content {
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;
}

</style>
