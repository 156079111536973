<template>
    <div>
  <div class="row">
    <div class="col-md-12">
      <input
        class="form-control form-control-lg"
        type="text"
        v-model="article.title"
        placeholder="How do you want to call your new article?"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <input
        class="form-control form-control-lg"
        type="text"
        v-model="article.slug"
        placeholder="slug"
      />
    </div>
  </div>

  <div id="editor">
    <div v-if="editor">
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        bold
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        italic
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        strike
      </button>
      <button
        @click="editor.chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        code
      </button>
      <button @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        paragraph
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        h1
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        h2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        h3
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        h4
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        h5
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        h6
      </button>
      <button
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        bullet list
      </button>
      <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        ordered list
      </button>
      <button
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button>
      <button
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
      >
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button>
      <button @click="editor.chain().focus().undo().run()">undo</button>
      <button @click="editor.chain().focus().redo().run()">redo</button>
    </div>
    <editor-content :editor="editor" />
  </div>

  <div class="row">
    <button type="submit" class="btn btn-success" @click="updateArticle">
      Update
    </button>
  </div>

  <div class="row" style="margin-top: 10px;" v-if="!article.isPublished">
    <button type="submit" class="btn btn-success" @click="publishArticle">
      Publish
    </button>
  </div>
    </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import {
  updateArticleAxiosv1,
  getArticleByIdAxiosv1Admin,
} from "@/api/article-services";

export default {
  components: {
    EditorContent,
  },
  data() {
    return {
      editor: null,
      id: this.$route.params.id.toString(),
      article: {},
    };
  },
  setup() {
    const caption = ref("Sample caption");

    return { caption };
  },

  methods: {
    async updateArticle() {
      this.article.content = this.editor.getHTML();
      await updateArticleAxiosv1(this.id, this.article);
      console.log("article updated.");
      window.location.href = "/admin/articles";
    },
    async publishArticle() {
      this.article.content = this.editor.getHTML();
      this.article.isDraft = false;
      await updateArticleAxiosv1(this.id, this.article);
      window.location.href = "/admin/articles";
    }
  },
  async mounted() {
    const { data } = await getArticleByIdAxiosv1Admin(
      this.$route.params.id.toString()
    );
    this.article = {
      title: data.title,
      slug: data.slug,
      content: data.content,
    };

    this.editor = new Editor({
      extensions: [StarterKit],
      content: this.article.content,
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style>
#editor {
  margin-top: 30px;
}
.ProseMirror > * + * {
  margin-top: 0.75em;
}
.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}
.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}
.ProseMirror code {
  background-color: rgba(97, 97, 97, 0.1);
  color: #616161;
}
.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}
.ProseMirror img {
  max-width: 100%;
  height: auto;
}
.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(13, 13, 13, 0.1);
  margin: 2rem 0;
}
</style>
