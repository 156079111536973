<template>
  <div class="box">
    <div class="box-header"><h3>Tech Stack</h3></div>

    <div class="box-body">
      <div class="row">
        <span><strong>Languages</strong></span>
      </div>

      <div class="row">
        <ul class="horizontal">
          <li>
            <span class="badge bg-green">C#</span>
          </li>
          <li>
            <span class="badge bg-green">Go</span>
          </li>

          <li>
            <span class="badge bg-green">JavaScript</span>
          </li>
          <li>
            <span class="badge bg-green">TypeScript</span>
          </li>
        </ul>
      </div>

      <div class="row">
        <span><strong>Tools / Technologies</strong></span>
      </div>

      <div class="row">
        <ul class="horizontal">
          <li>
            <span class="badge bg-green">.NET</span>
          </li>
          <li>
            <span class="badge bg-green">ASP.NET</span>
          </li>
          <li>
            <span class="badge bg-green">AWS</span>
          </li>
          <li>
            <span class="badge bg-green">Git</span>
          </li>
          <li>
            <span class="badge bg-green">Vim</span>
          </li>

          <li>
            <span class="badge bg-green">Apache Kafka</span>
          </li>

          <li>
            <span class="badge bg-green">Redis</span>
          </li>
          <li>
            <span class="badge bg-green">Microsoft Azure</span>
          </li>

          <li>
            <span class="badge bg-green">Entity Framework</span>
          </li>

          <li>
            <span class="badge bg-green">Docker</span>
          </li>

          <li>
            <span class="badge bg-green">Kubernetes</span>
          </li>

          <li>
            <span class="badge bg-green">Node.js</span>
          </li>
          <li>
            <span class="badge bg-green">PostgreSQL</span>
          </li>
          <li>
            <span class="badge bg-green">MSSQL</span>
          </li>

          <li>
            <span class="badge bg-green">ElasticSearch</span>
          </li>

          <li>
            <span class="badge bg-green">RabbitMQ</span>
          </li>

          <li>
            <span class="badge bg-green">GitHub Actions</span>
          </li>
          <li>
            <span class="badge bg-green">Azure Pipelines</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
.badge {
  font-size: 1em !important;
}

.bg-green {
  background-color: #525252 !important;
}
</style>
