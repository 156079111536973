import api from "./api-v1-config";
import { Page, PageType } from "./models/v1/page";
import { useStorage } from "vue3-storage";

const useToken = useStorage();
const token = useToken.getStorageSync('appToken');

export async function getAllPages(): Promise<Page[]> {
    return await (await api.get("Pages")).data;
}

export async function getPageBySlug(slug: string): Promise<Page> {
    return await (await api.get("Pages/" + slug)).data;
}

export async function updatePage(id: number, payload: any) {
    return await api.put("Pages/" + id, payload, { headers: { "Authorization": 'Bearer ' + token } });
}

export async function deletePageAxiosv1(id: any) {
    return await api.delete("page/" + id, { headers: { "Authorization": 'Bearer ' + token } });
}

export async function createPage(payload: any) {
    return await api.post("Pages", payload, { headers: { "Authorization": 'Bearer ' + token } });
}