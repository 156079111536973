<template>
  <div class="card">
    <div class="card-header">
      About
    </div>
    <div class="card-body" v-html="profile.summary">
    </div>
  </div>
</template>

<script>
export default {
  props: ['profile']
};
</script>

<style scoped>
.card-body {
  font-size: 1.05rem !important;
}
</style>