<template>
    <div>
        <About :summary="summary" :profile="profile"/>
        <TechStack/>
        <!--<Experience :experiences="experiences" />-->
        <Achievements/>
    </div>
</template>

<script>
import About from "@/views/About.vue"
import TechStack from "@/components/home/TechStack.vue";
import Achievements from "@/components/home/Achievements.vue";
import Experience from "@/components/home/Experience.vue";
import { getHome } from "@/api/app-service"
import { state } from "@/store/app-store";
import { decode } from 'js-base64';

export default {
  props: ['profile', 'experiences'],
  components: {
    About,
    TechStack,
    Achievements,
    // Experience
  },
  data() {
    return {
      summary: ""
    }
  },
  async mounted() {
    state.isLoading = true;
    try {
      const home = await getHome();
      this.summary = decode(home.data.summary);
    } catch(e) {
      console.log("err: "+ e);
    } finally {
      state.isLoading = false;
    }
  }
}
</script>
