import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Articles from "../views/admin/articles/Articles.vue";
import Pages from "../views/admin/pages/Pages.vue";
import NewArticle from "@/views/admin/articles/New.vue";
import Edit from "@/views/admin/articles/Edit.vue";
import CustomPage from "@/views/CustomPage.vue";
import { getAllPages } from "@/api/page-service";
import { Page } from "@/api/models/v1/page";
import EditPage from "@/views/admin/pages/Edit.vue";
import NewPage from "@/views/admin/pages/New.vue";
import Personal from "@/views/admin/personal/Personal.vue";
import LatestArticles from "@/views/LatestArticles.vue";
import NotFound from "@/views/NotFound.vue";
import ListExperiences from "@/views/admin/experience/List.vue";
import EditExperience from "@/views/admin/experience/Edit.vue";
import NewExperience from "@/views/admin/experience/New.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Fatih Dumanli" },
  },
  {
    path: "/blog",
    name: "Blog",
    component: LatestArticles,
    meta: { title: "Fatih Dumanli" },
  },
  {
    path: "/article/:id",
    name: "SingleArticle",
    component: () => import("../views/SingleArticle.vue"),
  },
  {
    path: "/pages/:slug",
    name: "PageView",
    component: () => import("../views/CustomPage.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/admin/admin.vue"),
    children: [
      {
        path: "login",
        name: "admin.login",
        component: () => import("@/views/admin/Login.vue"), 
      },
      {
        path: "",
        name: "admin.home",
        component: () => import("@/views/admin/DefaultContent.vue"), //import only user visists this page (lazy loading)
      },
      {
        path: "articles",
        name: "admin.articles",
        component: Articles, //eager loading. The resoure files of any pages are downloaded right after user visits the web app.
      },
      {
        path: "articles/new",
        name: "admin.articles.new",
        component: NewArticle,
      },
      {
        path: "articles/:id",
        name: "admin.articles.edit",
        component: Edit,
      },
      {
        path: "pages",
        name: "admin.pages",
        component: Pages,
      },
      {
        path: "pages/new",
        name: "admin.pages.new",
        component: NewPage,
      },
      {
        path: "pages/:slug",
        name: "admin.pages.edit",
        component: EditPage,
      },
      {
        path: "personal",
        name: "admin.personal",
        component: Personal,
      }, 
      {
        path: "experiences",
        name: "admin.experiences",
        component: ListExperiences,
      }, 
      {
        path: "experiences/new",
        name: "admin.experiences.new",
        component: NewExperience,
      }, 
      {
        path: "experiences/:id",
        name: "admin.experiences.edit",
        component: EditExperience,
      } 
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const aboutRoute = {
  path: "/about",
  name: "about",
  components: { default: CustomPage },
};
router.addRoute(aboutRoute);


export async function initDynamicRoutes(pages: any) {
  const x = 2;
  return x;
}

export default router;
