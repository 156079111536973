<template>
 <div>
  <h3>Full name</h3>
  <div class="row">
    <div class="col-md-12">
      <input class="form-control" 
      v-model="fullName" />
    </div>
  </div>

  <br/>

  <h3>Title</h3>
  <div class="row">
    <div class="col-md-12">
      <input class="form-control" 
      v-model="jobTitle" />
    </div>
  </div>
  <br/>

  <h3>Profile Photo Url</h3>
  <div class="row">
    <div class="col-md-12">
      <input class="form-control" v-model="profilePhotoUrl" />
    </div>
  </div>

  <br/>
  <h3>Summary</h3>
  <div id="editor">
    <div v-if="editor">
      <button
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        bold
      </button>
      <button
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        italic
      </button>
      <button
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        strike
      </button>
      <button
        @click="editor.chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        code
      </button>
      <button @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </button>
      <button @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </button>
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        paragraph
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        h1
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        h2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        h3
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        h4
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        h5
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        h6
      </button>
      <button
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        bullet list
      </button>
      <button
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        ordered list
      </button>
      <button
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button>
      <button
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ 'is-active': editor.isActive('blockquote') }"
      >
        blockquote
      </button>
      <button @click="editor.chain().focus().setHorizontalRule().run()">
        horizontal rule
      </button>
      <button @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </button>
      <button @click="editor.chain().focus().undo().run()">undo</button>
      <button @click="editor.chain().focus().redo().run()">redo</button>
    </div>

    <editor-content :editor="editor" />
  </div>
  <div class="row" style="margin-top: 10px;">
    <button class="btn btn-success" @click="saveProfile">Save Profile</button>
  </div>

  {{ pageStatus }}
  <br />
 </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { getProfile, updateProfile, updateSummary } from "@/api/profile-service";

export default {
  data() {
    return {
      summary: "",
      isLoaded: false,
      editor: null,
      fullName: "",
      profilePhotoUrl: "",
      jobTitle: "",
      pageStatus: ""
    };
  },
  components: {
    EditorContent,
  },
  async mounted() {
    try {
      const profile = await getProfile();
      this.profile = profile;
      this.fullName = profile.fullName;
      this.summary = profile.summary;
      this.profilePhotoUrl = profile.profilePhoto;
      this.jobTitle = profile.title;
    } catch (e) {
      console.log("err: " + e)
      this.summary = "";
    }

    this.isLoaded = true;
    this.editor = new Editor({
      extensions: [StarterKit],
      content: this.summary,
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    async saveProfile() {
      this.pageStatus = "Saving...";
      const summary = this.editor.getHTML();

      try
      {
          await updateProfile({
              title: this.jobTitle,
              fullName: this.fullName,
              profilePhoto: this.profilePhotoUrl,
              summary: summary
          });
          this.pageStatus = "Saved!";
      }
      catch(e)
      {
          this.pageStatus = e;
      }
    },
  },
};
</script>
