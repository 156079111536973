<template>
  <div :class="{ loader: true }">
    <img src="../assets/img/gear.svg" alt="My Happy SVG"/>
  </div>
</template>

<script>
import { state } from "@/store/app-store.ts";

export default {
  name: "LoadingScreen",
  computed: {
    isLoading() {
      return state.isLoading;
    } 
  }
};
</script>

<style>
.loader {
  background-color: #F1F2F3;
  bottom: 0;
  color: #000;
  display: block;
  font-size: 32px;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
}

.fadeout {
  animation: fadeout 2s forwards;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
</style>