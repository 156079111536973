<template>
    <div>
  <div class="blog-preview" v-for="blogPost in blogPosts" :key="blogPost.id">
    <div class="post-heading">
      <h2>
        <router-link :to="'/article/' + blogPost.id">{{
          blogPost.title
        }}</router-link>
      </h2>

      <p>
        <b-icon-calendar /> {{ blogPost.publishedAt }} | <b-icon-stopwatch />
        {{ blogPost.readTimeInMinutes }} min read
      </p>
    </div>
    <p>
      {{ strip(blogPost.caption) }}
    </p>

    <div class="post-footer">
      <button class="btn btn-default"><b-icon-chat /> 0</button>
    </div>
  </div>
    </div>
</template>

<script>
import { getArticlesAxiosv1 } from "@/api/article-services";
import dayjs, { Dayjs } from "dayjs";
import { state } from "@/store/app-store.ts";
import { encode, decode } from 'js-base64';


export default {
  name: "LatestArticles",
  components: {},
  data() {
    return {
      blogPosts: [],
    };
  },
  async mounted() {
    state.isLoading = true;
    try {
      const response = await getArticlesAxiosv1(true);
      this.blogPosts = response.data.map((article) => {
        return {
          id: article.id,
          title: article.title,
          slug: article.slug,
          caption: this.unescapeHTML(this.getCaption(article.content)) + "...",
          content: article.content,
          publishedAt: dayjs(article.publishedAt).format("MMM D, YYYY"),
          readTimeInMinutes: this.getReadingTime(article.content),
        };
      });
    } catch (e) {
      console.log("err: " + e);
    } finally {
      state.isLoading = false;
    }
  },
  computed: {
    strip() {
      return (v) => v.replace(/<\/?[^>]+>/gi, " ");
    },
    decodeBase64() {

      return (v) => decode(v);
    }
  },
  methods: {
    getReadingTime(content) {
      const text = content;
      const wpm = 225;
      const words = text.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm);
      return time;
    },
    getCaption(content) {
      if (content.length <= 500) {
        return this.strip(content);
      } else {
        return this.strip(content).toString().substring(0, 499);
      }
    },
    unescapeHTML(escapedHTML) {
      return escapedHTML
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&");
    },
  },
};
</script>

<style scoped>
.blog-preview {
  margin-bottom: 30px;
}
.card-footer a {
  float: right;
}
p.blog-caption {
  text-align: left !important;
}

h2 a {
  text-decoration: none;
  font-weight: bold;
  color: #000;
}

.post-heading p {
  font-size: 0.8rem;
  color: #5e5e5ef1;
}
</style>
