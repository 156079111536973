<template>
  <div>
    <h2>Pages</h2>
    <div class="row" style="margin-bottom: 20px">
      <router-link to="/admin/pages/new">Create new Page</router-link>
    </div>
    <div class="row">
      <div class="row">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Slug</th>
              <th scope="col" style="width: 20%">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="page in this.pages" :key="page.id">
              <td>
                <router-link :to="'/admin/pages/' + page.slug">{{
                  page.title
                }}</router-link>
              </td>
              <td>{{ page.slug }}</td>
              <td>
                <router-link
                  :to="'/admin/pages/' + page.slug"
                  class="btn btn-warning"
                >
                  <b-icon-pencil />
                </router-link>

                <button type="button" class="btn btn-danger">
                  <b-icon-trash @click="onDeletePage(page.id)" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllPages, deletePage } from "@/api/page-service";

export default {
  components: {},
  data() {
    return {
      pages: [],
    };
  },
  computed: {
    strip: function (v) {
      return v.replace(/<\/?[^>]+>/gi, " ");
    },
  },
  async mounted() {
    /*using this setup didn't work */
    //const { data } = await getAllPagesAxiosv1();
    //this.pages = data;

    this.pages = await getAllPages();
  },
  methods: {
    async onDeletePage(id) {
      await deletePage(id);
      this.pages = await getAllPages();
    },
  },
};
</script>

<style scoped>
button[type="button"] {
  margin-left: 5px;
}
</style>
